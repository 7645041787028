/* eslint-disable import/no-anonymous-default-export */
// import {

// } from "../actions/action";
import {
  GET_BRAND_LIST_REQUEST,
  GET_BRAND_LIST_SUCCESS,
  GET_BRAND_LIST_FAILURE,
} from "../actions/actions";
const INITIAL_STATE = {
  brandList: null,
  isLoadingGetAllBrand: false,
};

const BrandReducer = (state = INITIAL_STATE, action: any) => {
  switch (action.type) {
    case GET_BRAND_LIST_REQUEST:
      return {
        ...state,
        isLoadingGetAllBrand: action.payload.isLoadingGetAllBrand,
      };
    case GET_BRAND_LIST_SUCCESS:
      return {
        ...state,
        isLoadingGetAllBrand: action.payload.isLoadingGetAllBrand,
        brandList: action.payload.brandList,
      };
    case GET_BRAND_LIST_FAILURE:
      return {
        ...state,
        isLoadingGetAllBrand: action.payload.isLoadingGetAllBrand,
      };
    default:
      return state;
  }
};

export default BrandReducer;
