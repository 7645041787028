import { Navigate, Route, Routes, Outlet } from "react-router-dom";
import CustomBackdrop from "./components/Backdrop";
import PrivateRoute from "./privateRoute";
import React, { Suspense, lazy } from "react";
const RoutesPath = () => {
  const HomePage = lazy(() => import("./pages/home"));
  const ProductDetailPage = lazy(() => import("./pages/product-detail"));
  const CartPage = lazy(() => import("./pages/cart"));
  const ProductPage = lazy(() => import("./pages/product"));
  const CheckOutPage = lazy(() => import("./pages/checkout"));
  const AboutUsPage = lazy(() => import("./pages/about-us"));
  const ProductCategoryPage = lazy(() => import("./pages/product-category"));
  const ProfilePage = lazy(() => import("./pages/profile"));
  const StatusPage = lazy(() => import("./pages/status"));
  return (
    <Suspense fallback={<CustomBackdrop isLoading={true} />}>
      <Routes>
        <Route path="/" element={<Outlet />}>
          <Route path="/" element={<HomePage />} />
          <Route path="/home" element={<HomePage />} />
          <Route path="/product-detail/:id" element={<ProductDetailPage />} />
          <Route path="/cart" element={<CartPage />} />
          <Route path="/products" element={<ProductPage />} />
          <Route
            path="/product-category/:id"
            element={<ProductCategoryPage />}
          />
          <Route path="/checkout/:order_number" element={<CheckOutPage />} />
          <Route path="/about-us" element={<AboutUsPage />} />
          <Route path="/payment/status" element={<StatusPage />} />
          <Route element={<PrivateRoute />}>
            <Route path="/profile" element={<ProfilePage />} />
          </Route>
        </Route>

        {/* <Route
          path="/profile"
          element={<PrivateRoute component={<Profile />} />}
        /> */}
        {/* <Route path="/profile" element={<Profile />} /> */}
      </Routes>
    </Suspense>
  );
};

export default RoutesPath;
