/* eslint-disable import/no-anonymous-default-export */
// import {

import {
  CREATE_PAYMENT_FAILURE,
  CREATE_PAYMENT_REQUEST,
  CREATE_PAYMENT_SUCCESS,
  REDIRECT_PAYMENT_FAILED,
  REDIRECT_PAYMENT_REQUEST,
  REDIRECT_PAYMENT_SUCCESS,
} from "../actions/actions";

const INITIAL_STATE = {
  isLoadingCreatePayment: false,
  isLoadingGetRedirectPayment: false,
  redirectPaymentData: null,
};

const PaymentReducer = (state = INITIAL_STATE, action: any) => {
  switch (action.type) {
    case CREATE_PAYMENT_REQUEST:
      return {
        ...state,
        isLoadingCreatePayment: action.payload.isLoadingCreatePayment,
      };
    case CREATE_PAYMENT_SUCCESS:
      return {
        ...state,
        isLoadingCreatePayment: action.payload.isLoadingCreatePayment,
      };
    case CREATE_PAYMENT_FAILURE:
      return {
        ...state,
        isLoadingCreatePayment: action.payload.isLoadingCreatePayment,
      };
    case REDIRECT_PAYMENT_REQUEST:
      return {
        ...state,
        isLoadingGetRedirectPayment: action.payload.isLoadingGetRedirectPayment,
      };
    case REDIRECT_PAYMENT_SUCCESS:
      return {
        ...state,
        isLoadingGetRedirectPayment: action.payload.isLoadingGetRedirectPayment,
        redirectPaymentData: action.payload.redirectPaymentData,
      };
    case REDIRECT_PAYMENT_FAILED:
      return {
        ...state,
        isLoadingGetRedirectPayment: action.payload.isLoadingGetRedirectPayment,
      };
    default:
      return state;
  }
};

export default PaymentReducer;
