/* eslint-disable import/no-anonymous-default-export */
// import {

// } from "../actions/action";
import {
  GET_ALL_PRODUCT_BY_BRAND_FAILURE,
  GET_ALL_PRODUCT_BY_BRAND_REQUEST,
  GET_ALL_PRODUCT_BY_BRAND_SUCCESS,
  GET_ALL_PRODUCT_FAILURE,
  GET_ALL_PRODUCT_REQUEST,
  GET_ALL_PRODUCT_SUCCESS,
  GET_POPULAR_PRODUCT_FAILURE,
  GET_POPULAR_PRODUCT_REQUEST,
  GET_POPULAR_PRODUCT_SUCCESS,
  GET_PRODUCT_DETAIL_FAILURE,
  GET_PRODUCT_DETAIL_REQUEST,
  GET_PRODUCT_DETAIL_SUCCESS,
} from "../actions/actions";
const INITIAL_STATE = {
  popularList: null,
  isLoadingGetPopularProduct: false,
  productDetail: null,
  isLoadingGetProductDetail: false,
  isLoadingGetAllProduct: false,
  productList: null,
  isLoadingGetAllProductByBrand: false,
  productByBrandList: null,
};

const ProductReducer = (state = INITIAL_STATE, action: any) => {
  switch (action.type) {
    case GET_POPULAR_PRODUCT_REQUEST:
      return {
        ...state,
        isLoadingGetPopularProduct: action.payload.isLoadingGetPopularProduct,
      };
    case GET_POPULAR_PRODUCT_SUCCESS:
      return {
        ...state,
        isLoadingGetPopularProduct: action.payload.isLoadingGetPopularProduct,
        popularList: action.payload.popularList,
      };
    case GET_POPULAR_PRODUCT_FAILURE:
      return {
        ...state,
        isLoadingGetPopularProduct: action.payload.isLoadingGetPopularProduct,
      };
    case GET_PRODUCT_DETAIL_REQUEST:
      return {
        ...state,
        isLoadingGetProductDetail: action.payload.isLoadingGetProductDetail,
      };
    case GET_PRODUCT_DETAIL_SUCCESS:
      return {
        ...state,
        isLoadingGetProductDetail: action.payload.isLoadingGetProductDetail,
        productDetail: action.payload.productDetail,
      };
    case GET_PRODUCT_DETAIL_FAILURE:
      return {
        ...state,
        isLoadingGetProductDetail: action.payload.isLoadingGetProductDetail,
      };
    case GET_ALL_PRODUCT_REQUEST:
      return {
        ...state,
        isLoadingGetAllProduct: action.payload.isLoadingGetAllProduct,
      };
    case GET_ALL_PRODUCT_SUCCESS:
      return {
        ...state,
        isLoadingGetAllProduct: action.payload.isLoadingGetAllProduct,
        productList: action.payload.productList,
      };
    case GET_ALL_PRODUCT_FAILURE:
      return {
        ...state,
        isLoadingGetAllProduct: action.payload.isLoadingGetAllProduct,
      };

    case GET_ALL_PRODUCT_BY_BRAND_REQUEST:
      return {
        ...state,
        isLoadingGetAllProductByBrand:
          action.payload.isLoadingGetAllProductByBrand,
      };
    case GET_ALL_PRODUCT_BY_BRAND_SUCCESS:
      return {
        ...state,
        isLoadingGetAllProductByBrand:
          action.payload.isLoadingGetAllProductByBrand,
        productByBrandList: action.payload.productByBrandList,
      };
    case GET_ALL_PRODUCT_BY_BRAND_FAILURE:
      return {
        ...state,
        isLoadingGetAllProductByBrand:
          action.payload.isLoadingGetAllProductByBrand,
      };
    default:
      return state;
  }
};

export default ProductReducer;
