/* eslint-disable import/no-anonymous-default-export */
// import {

// } from "../actions/action";
import {
  CHECK_CART_FAILURE,
  CHECK_CART_REQUEST,
  CHECK_CART_SUCCESS,
} from "../actions/actions";
const INITIAL_STATE = {
  cartList: null,
  isLoadingCheckCart: false,
};

const CartReducer = (state = INITIAL_STATE, action: any) => {
  switch (action.type) {
    case CHECK_CART_REQUEST:
      return {
        ...state,
        isLoadingCheckCart: action.payload.isLoadingCheckCart,
      };
    case CHECK_CART_SUCCESS:
      return {
        ...state,
        isLoadingCheckCart: action.payload.isLoadingCheckCart,
        cartList: action.payload.cartList,
      };
    case CHECK_CART_FAILURE:
      return {
        ...state,
        isLoadingCheckCart: action.payload.isLoadingCheckCart,
      };
    default:
      return state;
  }
};

export default CartReducer;
