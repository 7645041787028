/* eslint-disable import/no-anonymous-default-export */
// import {

// } from "../actions/action";
import {
  GET_CHECKOUT_ORDER_FAILURE,
  GET_CHECKOUT_ORDER_REQUEST,
  GET_CHECKOUT_ORDER_SUCCESS,
  GET_USER_ORDER_LIST_FAILURE,
  GET_USER_ORDER_LIST_REQUEST,
  GET_USER_ORDER_LIST_SUCCESS,
  PROCEED_CHECK_OUT_FAILURE,
  PROCEED_CHECK_OUT_REQUEST,
  PROCEED_CHECK_OUT_SUCCESS,
} from "../actions/actions";
const INITIAL_STATE = {
  checkoutData: null,
  isLoadingGetCheckOutOrder: false,
  isLoadingProceedCheckOut: false,
  isLoadingGetUserOrder: false,
  userOrder: null,
};

const OrderReducer = (state = INITIAL_STATE, action: any) => {
  switch (action.type) {
    case GET_CHECKOUT_ORDER_REQUEST:
      return {
        ...state,
        isLoadingGetCheckOutOrder: action.payload.isLoadingGetCheckOutOrder,
      };
    case GET_CHECKOUT_ORDER_SUCCESS:
      return {
        ...state,
        isLoadingGetCheckOutOrder: action.payload.isLoadingGetCheckOutOrder,
        checkoutData: action.payload.checkoutData,
      };
    case GET_CHECKOUT_ORDER_FAILURE:
      return {
        ...state,
        isLoadingGetCheckOutOrder: action.payload.isLoadingGetCheckOutOrder,
      };
    case PROCEED_CHECK_OUT_REQUEST:
      return {
        ...state,
        isLoadingProceedCheckOut: action.payload.isLoadingProceedCheckOut,
      };
    case PROCEED_CHECK_OUT_SUCCESS:
      return {
        ...state,
        isLoadingProceedCheckOut: action.payload.isLoadingProceedCheckOut,
      };
    case PROCEED_CHECK_OUT_FAILURE:
      return {
        ...state,
        isLoadingProceedCheckOut: action.payload.isLoadingProceedCheckOut,
      };
    case GET_USER_ORDER_LIST_REQUEST:
      return {
        ...state,
        isLoadingGetUserOrder: action.payload.isLoadingGetUserOrder,
      };
    case GET_USER_ORDER_LIST_SUCCESS:
      return {
        ...state,
        isLoadingGetUserOrder: action.payload.isLoadingGetUserOrder,
        userOrder: action.payload.userOrder,
      };
    case GET_USER_ORDER_LIST_FAILURE:
      return {
        ...state,
        isLoadingGetUserOrder: action.payload.isLoadingGetUserOrder,
      };
    default:
      return state;
  }
};

export default OrderReducer;
