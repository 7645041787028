import { useNavigate, Outlet } from "react-router-dom";
import { useEffect } from "react";
const PrivateRoute = () => {
  const navigate = useNavigate();
  const isAuthenticated = localStorage?.getItem("currentUser") ? true : false; //add a dynamic method call

  useEffect(() => {
    if (!isAuthenticated) {
      return navigate("/", { replace: true });
    }
  }, [isAuthenticated, navigate]);

  return (
    <>
      <Outlet />
    </>
  );
};

export default PrivateRoute;
