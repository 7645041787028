import { Action, ThunkAction, configureStore } from "@reduxjs/toolkit";
import brand from "../reducers/brand";
import ProductReducer from "../reducers/product";
import CartReducer from "../reducers/cart";
import OrderReducer from "../reducers/order";
import UserReducer from "../reducers/user";
import SnackBarReducer from "../reducers/snackbar";
import PaymentReducer from "../reducers/payment";
export const store = configureStore({
  reducer: {
    brand: brand,
    product: ProductReducer,
    cart: CartReducer,
    order: OrderReducer,
    user: UserReducer,
    snackbar: SnackBarReducer,
    payment: PaymentReducer,
  },
});

export type AppDispatch = typeof store.dispatch;
export type RootState = ReturnType<typeof store.getState>;
export type AppThunk<ReturnType = void> = ThunkAction<
  ReturnType,
  RootState,
  unknown,
  Action<string>
>;
